<template>
  <div style="height: 100%;background: #f7f8fa;">
    <van-nav-bar title="个人信息" :z-index="999" :fixed="true" left-arrow @click-left="back" />
    <div class="tops" >
       <van-cell title="ID号"  :value="userInfo.id" />
      <van-cell title="头像" is-link class="touxian" @click="chetupian">
        <!-- 使用 title 插槽来自定义标题 -->
        <template #default>
          <img class="toux" :src="userInfo.avatar" alt />
        </template>
      </van-cell>
      <van-cell title="真实姓名" @click="xiugai(0)" is-link :value="userInfo.userName" />
      <van-cell title="手机号" @click="xiugai(1)" is-link :value="userInfo.phone" />
      <van-cell title="性别" is-link :value="userInfo.sex == '0'?'女':'男'" @click="xinbie" />
      <van-cell title="昵称" @click="xiugai(2)" is-link :value="userInfo.realName" />
      <van-cell
        title="生日信息"
        is-link
        :value="userInfo.entryTime?userInfo.entryTime.substring(0,10):''"
        @click="shengri"
      />
      <van-cell
        title="默认门店"
        @click="tomendian"
        style="margin-top: 10px;"
        is-link
        :value="shangjias.name"
      />
      <van-cell title="会员等级" is-link :value="userInfo.grade" />
      <van-cell title="我的积分" is-link value />
      <van-cell title="微信绑定" style="margin-top: 10px;" is-link value="解绑" />
      <van-cell title="收货地址" style="margin-top: 10px;" is-link value />
    </div>
    <van-action-sheet
      v-model="show"
      :actions="actions"
      cancel-text="取消"
      @select="onSelect"
      close-on-click-action
      @cancel="onCancel"
    />
    <van-popup v-model="show2" position="bottom" :style="{ height: '30%' }">
      <van-datetime-picker
        :min-date="minDate"
        :max-date="maxDate"
        v-model="currentDate"
        @confirm="shijian"
        @cancel="guanbishijian"
        type="date"
        title="选择年月日"
      />
    </van-popup>
    <div class="bott">
      <div class="bott_c" @click="tuichu">退出登录</div>
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
// import wx from "weixin-js-sdk";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  // mixins: [preventBack], //注入
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo"))
        ? JSON.parse(localStorage.getItem("userInfo"))
        : {},
      loading: false,
      finished: true,
      shangjias: JSON.parse(sessionStorage.getItem("shangjia")),
      currentDate: new Date(),
      active: 2,
      show: false,
      show2: false,
      minDate: new Date(1950, 0, 1),
      maxDate: new Date(2100, 10, 1),
      actions: [
        { name: "男", sex: "1" },
        { name: "女", sex: "0" }
      ],
      list: []
    };
  },

  methods: {
    onCancel() {
      Toast("取消");
    },
    //门店list
    tomendian() {
      this.$router.push({
        path: "Store_list",
        query: {
          isok: false
        }
      });
    },
    //选择时间
    shijian(value) {
      this.userInfo.entryTime = this.$moment(value).format("YYYY-MM-DD");
      let data = {
        entryTime: this.userInfo.entryTime,
        gid: this.shangjias.id
      };
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      userModel
        .info(data)
        .then(e => {
          localStorage.setItem("userInfo", JSON.stringify(e.data));
          loading.clear();
        })
        .catch(() => loading.clear());
      this.show2 = false;
    },
    guanbishijian() {
      this.show2 = false;
    },
    //选择性别
    onSelect(item) {
      console.log(item);
      let data = {
        sex: item.sex,
        gid: this.shangjias.id
      };
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      userModel
        .info(data)
        .then(e => {
          this.userInfo.sex = item.sex;
          localStorage.setItem("userInfo", JSON.stringify(e.data));
          loading.clear();
        })
        .catch(() => loading.clear());
    },
    xinbie() {
      this.show = true;
    },
    shengri() {
      this.show2 = true;
    },
    xiugai(item) {
      this.$router.push({
        path: "modification",
        query: {
          cid: item
        }
      });
    },
    renzhen() {
      this.$router.push({
        path: "authentication"
        // query: {
        //   status: status
        // }
      });
    },
    tianjia() {
      this.$router.push({
        path: "model_list"
        // query: {
        //   status: status
        // }
      });
    },
    onLoad() {
      const data = {
        carNo: localStorage.getItem("carNo"),
        status: 2
      };

      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      orderModel
        .listHistoryByCustomer(data)
        .then(e => {
          loading.clear();
          this.list = e.data;
        })
        .catch(() => loading.clear());
    },
    tuichu() {
      var _this = this;
      localStorage.removeItem("Authorization");
      localStorage.removeItem("userInfo");
      sessionStorage.removeItem("chexin");

      // sessionStorage.removeItem("shangjia");

      _this.$toast("退出成功");
      setTimeout(() => {
        this.$router.push({
          name: "mine"
        });
      }, 600);
    },
    //上传车图片
    chetupian() {
      var _this = this;
      // wx.ready(() => {
      wx.chooseImage({
        count: 1, // 默认9
        sizeType: ["original", "compressed"], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ["album", "camera"], // 可以指定来源是相册还是相机，默认二者都有
        success: function(res) {
          console.log(res);
          var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片

          wx.uploadImage({
            localId: res.localIds[0], // 需要上传的图片的本地ID，由chooseImage接口获得
            isShowProgressTips: 1, // 默认为1，显示进度提示
            success: function(res) {
              var serverId = res.serverId; // 返回图片的服务器端I
              const data = {
                mediaId: serverId
              };

              const loading = _this.$toast.loading({
                mask: true,
                duration: 0,
                message: "加载中..."
              });
              userModel
                .uploadPicture(data)
                .then(e => {
                  loading.clear();
                  _this.userInfo.avatar = e.data;
                  let data = {
                    avatar: e.data,
                    gid: this.shangjias.id
                  };
                  userModel
                    .info(data)
                    .then(e => {
                      localStorage.setItem("userInfo", JSON.stringify(e.data));
                    })
                    .catch(() => loading.clear());
                })
                .catch(() => loading.clear());
            }
          });
        }
      });
      // });
    },
    getConfig() {
      let that = this;
      userModel
        .config({ url: location.href.split("#")[0], gid: this.shangjias.id })
        .then(res => {
          if (res.code == 200) {
            console.log(res);
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.data.appid, // 必填，公众号的唯一标识
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.signature, // 必填，签名
              jsApiList: [
                "chooseImage",
                "getLocalImgData",
                "downloadImage",
                "uploadImage"
              ] // 必填，需要使用的JS接口列表
            });
            wx.ready(() => {
              // wx.onMenuShareTimeline({
              //   title: that.list.garageName,
              //   link: link,
              //   imgUrl: "",
              //   success: function() {}
              // });
            });
          } else {
            this.$toast(res.data.msg);
          }
        });
    },
    back() {
      this.$router.push({
        path: "mine"
      });
    }
  },
  created() {
    this.getConfig();
    // this.onLoad()
  }
};
</script>

<style lang="less" scoped>
.toux {
  width: 40px;
  height: 40px;

  vertical-align: middle;
  border-radius: 50%;
}
.tops{
padding:46px 0px;
}
// 底部
.bott {
  height: 55px;
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0;
  padding: 0 12px;
}
.bott_c {
  margin-top: 10px;
  font-size: 14px;
  background: #df3447;
  color: #fff;
  text-align: center;
  font-weight: bold;
  padding: 7px 18px;
  border-radius: 19px;
}
.van-cell__value {
  vertical-align: middle;
}
.touxian .van-icon {
  margin-top: 10px;
}
.touxian {
  line-height: 40px;
}
.van-icon {
  vertical-align: middle;
}
.van-cell__value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 70%;
}
</style>
